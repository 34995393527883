import './App.css'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Home from "./pages/Home";
// import Survey from "./pages/Survey";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index path="/" element={<Home />}/>
                {/*<Route path="/survey" element={<Survey />} />*/}
            </Routes>
        </BrowserRouter>
    )
}